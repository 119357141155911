/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-undef */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { adsListViews, adsTypes } from 'Core/constants'
import { getAds, getAdsLoading } from '../../selectors'
import { getPaginationParams } from '../../../pagination/selectors'
import { fetchAds, setLoading } from '../../actions'
import { Header, GridViewIcon, CardViewIcon } from '../Header'
import { GridViewList } from '../GridView'
import { CardViewList } from '../CardView'
import { connect } from 'react-redux'
import { TextPreloader } from 'SRC/ui/Preloader'
import Router from 'next/router'
import { setAppInternalTransitionFunc } from 'SRC/modules/common/actions'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { AdBreadcrumbs } from 'SRC/modules/ads/shared/components/AdBreadcrumbs'
import { Panel } from '../Panel'
import { routes } from 'Core/routes'
import { SavedSearch } from '../../../filter/components'
import Link from 'next/link'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import TopLoader from 'react-top-loader'
import config from 'SRC/config/config.yaml'

const AfterAdsBannerLarge = () => {
  useDfpSlot({
    id: config.banners.adsList.afterAdsLarge.slotId || null,
    sizes: config.banners.adsList.afterAdsLarge.sizes || [],
    path: config.banners.adsList.afterAdsLarge
      ? `/${config.banners.dfpNetworkId}/${config.banners.adsList.afterAdsLarge.adUnit}`
      : null
  })

  return <div id={config.banners.adsList.afterAdsLarge.slotId} style={{ width: 895, height: 100 }} />
}

const AfterAdsBanners = () => {
  useDfpSlot({
    id: config.banners.adsList.afterAds.slotId || null,
    sizes: config.banners.adsList.afterAds.sizes || [],
    path: config.banners.adsList.afterAds ? `/${config.banners.dfpNetworkId}/${config.banners.adsList.afterAds.adUnit}` : null
  })

  useDfpSlot({
    id: config.banners.adsList.afterAdsSecond.slotId || null,
    sizes: config.banners.adsList.afterAdsSecond.sizes || [],
    path: config.banners.adsList.afterAdsSecond
      ? `/${config.banners.dfpNetworkId}/${config.banners.adsList.afterAdsSecond.adUnit}`
      : null
  })

  return (
    <React.Fragment>
      <div key='ad-list-banner-1' style={{ display: 'inline-block', width: 590, maxHeight: 125, marginTop: 4 }}>
        <div id={config.banners.adsList.afterAds.slotId} style={{ width: 590, height: 120 }} />
      </div>

      <div key='ad-list-banner-2' style={{ display: 'inline-block', width: 590, maxHeight: 125, marginLeft: 13, marginTop: 4 }}>
        <div id={config.banners.adsList.afterAdsSecond.slotId} style={{ width: 590, height: 120 }} />
      </div>
    </React.Fragment>
  )
}

const MobileTopBanner = () => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const handleWindowResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  useDfpSlot({
    id: config.banners.adsList.mobileTop.slotId || null,
    sizes: config.banners.adsList.mobileTop.sizes || [],
    path: config.banners.adsList.mobileTop ? `/${config.banners.dfpNetworkId}/${config.banners.adsList.mobileTop.adUnit}` : null
  })

  return config.banners.adsList.mobileTop ? (
    <div
      id={config.banners.adsList.mobileTop.slotId}
      style={{ width: windowWidth <= 727 ? 320 : 728, margin: 'auto', position: 'relative' }}
    />
  ) : null
}

export class List extends React.Component {
  constructor(props) {
    super(props)

    this.adsListViews = config.adsList.adsListViews

    this.tableRef = React.createRef()
    this.listRef = React.createRef()

    this.state = {
      style: this.adsListViews[Object.keys(this.adsListViews)[0]],
      isLoading: false
    }

    if (Router.router) {
      Router.router.events.on('routeChangeStart', this.onRouteChangeStartHandler)
      Router.router.events.on('routeChangeComplete', this.onRouteChangeCompleteHandler)
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll)
    this.props.setAppInternalTransitionFunc(this.isInternalTransition)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
    this.props.setAppInternalTransitionFunc(null)
  }

  onRouteChangeStartHandler = url => {
    if (this.isInternalTransition(url)) {
      this.showSpinner()
    }
  }

  onRouteChangeCompleteHandler = (url) => {
    this.hideSpinner()
  }

  isInternalTransition = url =>
    /^\/naslovna\/.+$/.test(url) ||
    /^\/.+\/all\/\d+$/.test(url) ||
    /^\/.+\/pretraga/.test(url) ||
    /^\/$/.test(url) ||
    /^\/\?[A-Za-z0-9&=]*$/.test(url) ||
    /^\/.+\?[A-Za-z0-9&=]*$/.test(url) ||
    !routes.includes(url)

  showSpinner = () => {
    this.props.setLoading(true)
  }

  hideSpinner = () => {
    this.props.setLoading(false)
  }

  setTableView = () => {
    this.setState({ ...this.state, style: adsListViews.TABLE })
  }

  setListView = () => {
    this.setState({ ...this.state, style: adsListViews.LIST })
  }

  handleScroll = () => {
    const {
      ads,
      isLoading,
      fetchAds,
      enabledInfiniteScroll,
      paginationParams
    } = this.props

    if (!enabledInfiniteScroll || paginationParams.adsType === adsTypes.free) return

    const actualAdsLength = Array.isArray(ads)
      ? ads.filter(ad => ad.id && !ad.banner).length
      : 0

    let currentRef

    if (this.state.style === adsListViews.TABLE) currentRef = this.tableRef.current
    else currentRef = this.listRef.current

    const blockBorder = currentRef.scrollTop + currentRef.clientHeight
    const isScrolledToBotton = (window.innerHeight + window.scrollY) >= document.body.offsetHeight

    if (window.pageYOffset >= blockBorder / 2 || isScrolledToBotton) {
      if (!isLoading && actualAdsLength < paginationParams.paidCount) {
        const loadOptions = {
          page: Number(paginationParams.currentPage) + 1,
          type: paginationParams.adsType,
          limit: paginationParams.countPerPage,
          status: 'Published',
          sortBy: 'dateDesc'
        }

        fetchAds(
          loadOptions,
          {
            ...paginationParams,
            currentPage: Number(paginationParams.currentPage) + 1
          },
          ads
        )
      }
    }
  }

  render() {
    const { isMobileDevice, pageUrlItems, paginationParams, title, isSearchPage, isUserSearch, showBreadCrumbs } = this.props

    return (
      <div className='oglasi'>
        {isMobileDevice ? <MobileTopBanner /> : null}

        {!isUserSearch && showBreadCrumbs ? <AdBreadcrumbs pageUrlItems={pageUrlItems} /> : null}

        <div className='oglasi-filter clearfix'>
          {!isMobileDevice ? (
            <Panel
              currentPage={paginationParams.currentPage}
              filterValues={this.props.filterValues}
              formStyle={this.props.formStyle}
              isUserSearch={this.props.isUserSearch}
            />
          ) : null
          }
          {!isMobileDevice ? (
            <div className='oglasi-filter-grid'>
              <ul>
                {this.adsListViews.TABLE ? <GridViewIcon onClick={this.setTableView} style={this.state.style} /> : null}
                {this.adsListViews.LIST ? <CardViewIcon onClick={this.setListView} style={this.state.style} /> : null}
                <SavedSearch filterValues={this.props.filterValues} formStyle={this.props.formStyle} />
              </ul>
            </div>
          ) : null
          }
        </div>

        <div className='oglasi-content'>
          {this.adsListViews.TABLE ? (
            <GridViewList
              ads={this.props.ads}
              style={this.state.style}
              ref={this.tableRef}
              noAdsMessage={this.props.noAdsMessage}
            />
          ) : null}

          {this.adsListViews.LIST && !isMobileDevice ? (
            <CardViewList
              ads={this.props.ads}
              style={this.state.style}
              ref={this.listRef}
              noAdsMessage={this.props.noAdsMessage}
            />
          ) : null}

          {this.props.isLoading ? <TextPreloader /> : null}

          {
            this.props.enabledInfiniteScroll && paginationParams.adsType !== adsTypes.free
              ? (
                <Link
                  href={{ pathname: '/', query: { page: 1, type: adsTypes.free } }}
                  as={{ pathname: `/naslovna/${adsTypes.free}/1` }}
                >
                  <a className='form-submit-btn'>Učitaj još oglasa</a>
                </Link>
              ) : null
          }

          {!isMobileDevice && isUserSearch ? <AfterAdsBannerLarge /> : null}
          {!isMobileDevice && !isUserSearch ? <AfterAdsBanners /> : null}
        </div>
        <TopLoader color='#a57bdb' show={this.props.isLoading} className='top-loader' />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    ads: getAds(state),
    isLoading: getAdsLoading(state),
    paginationParams: getPaginationParams(state),
    isMobileDevice: getIsMobileDevice(state)
  }
}

List.propTypes = {
  ads: PropTypes.array.isRequired,
  enabledInfiniteScroll: PropTypes.bool,
  fetchAds: PropTypes.func,
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    countPerPage: PropTypes.number.isRequired,
    paidCount: PropTypes.number.isRequired,
    freeCount: PropTypes.number.isRequired,
    adsType: PropTypes.oneOf([
      adsTypes.paid,
      adsTypes.all,
      adsTypes.free
    ]).isRequired,
    totalCount: PropTypes.number.isRequired,
    paidAdsPage: PropTypes.number
  }).isRequired,
  noAdsMessage: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isSearchPage: PropTypes.bool.isRequired,
  showBreadCrumbs: PropTypes.bool,
  title: PropTypes.string,
  pageUrlItems: PropTypes.object,
  isUserSearch: PropTypes.bool.isRequired,
  filterValues: PropTypes.object,
  formStyle: PropTypes.string,
  isMobileDevice: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  setAppInternalTransitionFunc: PropTypes.func.isRequired
}

List.defaultProps = {
  ads: [],
  enabledInfiniteScroll: false,
  isSearchPage: false,
  isLoading: false,
  isUserSearch: false,
  showBreadCrumbs: true
}

export default connect(mapStateToProps, { fetchAds, setLoading, setAppInternalTransitionFunc })(List)
